@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
body {
    background-color: white !important;
}

html, body, #root #root>div {
    font-family: 'Lora', serif;
    min-height: 100%;
    margin: 0;
    height: 100%;
}
.home-container {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
}

.background-video {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    height: 100%;
    object-fit: cover;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: -1;
    -webkit-filter: brightness(50%) grayscale(100%);
            filter: brightness(50%) grayscale(100%);
    pointer-events: none;
}

.logo {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

#arrow-icon {
    font-size: 100px;
    color: white;
}

@media only screen and (max-width: 576px) {
    .logo {
        width: 100%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 992px) {
    .logo {
        width: 20%;
    }
}

@media only screen and (min-width: 992px) {
    .logo {
        width: 35%;
    }
}
.custom-card {
    border-radius: 30px !important;
    overflow: hidden;
}

.custom-card-text {
    overflow: hidden;
    min-height: 90px;
    max-height: 90px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-card-title {
    min-height: 100px;
}

.custom-card-footer {
    background-color: #003e2f;
    overflow: hidden;
    cursor: pointer;
}

.custom-card-button {
    background-color: #003e2f;
    font-size: 50px;
    color: white;
    border: none;  
    cursor: pointer;
}

a.custom-card-button:hover {
    color: white;
}

.custom-card-link {
    min-height: 400px;
}

.title-link {
    height: 90%; 
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.custom-card-title-link {
    height: 250px;
}
.about-data {
    font-weight: 500;
}

#woman-photo {
    border-radius: 30px;
}


#contact-col a, p {
    color: black;
    text-decoration: none;
    font-size: 20px;
}

@media only screen and (max-width: 768px) {
    #contact-col a, p {
        font-size: 15px;
    }
  }

#contact-col div {
    margin: 30px;
    text-align: center;
}

.contact-icon {
    margin-right: 10px;
}
#custom-navbar {
    background-color: #003e2f;
    opacity: 0.9;
}

.custom-nav-link {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 14px;
    text-decoration: none;
    padding: 8px;
    color: white;
    text-align: center;
}

.custom-nav-link span {
    padding: 5px;
}

.custom-nav-link:hover {
    color: white;
}

.custom-logo {
    opacity: 0.9;
    background-color: #003e2f;
}

#phone-container-logo {
    background-color: #003e2f;
}

.scrolled-down{
    -webkit-transform:translateY(-100%);
            transform:translateY(-100%); transition: all 0.3s ease-in-out;
  }
  .scrolled-up{
    -webkit-transform:translateY(0);
            transform:translateY(0); transition: all 0.3s ease-in-out;
  }
#outer-footer-container {
    color: white;
    background-color: #003e2f;
    padding: 10px 0px 10px 0px;
}

#outer-footer-container a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding-left: 15px;
}

.footer-icon {
    font-size: 20px;
    margin: 20px 0px 20px 10px;
}
