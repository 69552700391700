.custom-card {
    border-radius: 30px !important;
    overflow: hidden;
}

.custom-card-text {
    overflow: hidden;
    min-height: 90px;
    max-height: 90px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-card-title {
    min-height: 100px;
}

.custom-card-footer {
    background-color: #003e2f;
    overflow: hidden;
    cursor: pointer;
}

.custom-card-button {
    background-color: #003e2f;
    font-size: 50px;
    color: white;
    border: none;  
    cursor: pointer;
}

a.custom-card-button:hover {
    color: white;
}

.custom-card-link {
    min-height: 400px;
}

.title-link {
    height: 90%; 
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.custom-card-title-link {
    height: 250px;
}