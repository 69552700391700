@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
    background-color: white !important;
}

html, body, #root #root>div {
    font-family: 'Lora', serif;
    min-height: 100%;
    margin: 0;
    height: 100%;
}