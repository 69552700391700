.home-container {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
}

.background-video {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
    filter: brightness(50%) grayscale(100%);
    pointer-events: none;
}

.logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#arrow-icon {
    font-size: 100px;
    color: white;
}

@media only screen and (max-width: 576px) {
    .logo {
        width: 100%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 992px) {
    .logo {
        width: 20%;
    }
}

@media only screen and (min-width: 992px) {
    .logo {
        width: 35%;
    }
}