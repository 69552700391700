#custom-navbar {
    background-color: #003e2f;
    opacity: 0.9;
}

.custom-nav-link {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 14px;
    text-decoration: none;
    padding: 8px;
    color: white;
    text-align: center;
}

.custom-nav-link span {
    padding: 5px;
}

.custom-nav-link:hover {
    color: white;
}

.custom-logo {
    opacity: 0.9;
    background-color: #003e2f;
}

#phone-container-logo {
    background-color: #003e2f;
}

.scrolled-down{
    transform:translateY(-100%); transition: all 0.3s ease-in-out;
  }
  .scrolled-up{
    transform:translateY(0); transition: all 0.3s ease-in-out;
  }