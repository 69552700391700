#outer-footer-container {
    color: white;
    background-color: #003e2f;
    padding: 10px 0px 10px 0px;
}

#outer-footer-container a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding-left: 15px;
}

.footer-icon {
    font-size: 20px;
    margin: 20px 0px 20px 10px;
}