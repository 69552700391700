#contact-col a, p {
    color: black;
    text-decoration: none;
    font-size: 20px;
}

@media only screen and (max-width: 768px) {
    #contact-col a, p {
        font-size: 15px;
    }
  }

#contact-col div {
    margin: 30px;
    text-align: center;
}

.contact-icon {
    margin-right: 10px;
}